import _ from 'lodash';

export class EventService {
  static getEventBus() {
    let notifyInfo = {};

    return {
      on,
      un,
      fireEvent,
      unregisterAll,
    };

    /**
     * @param {String|Object} event - Could be string type with multiple events divided by comma.
     *                                For example, 'foo' or 'foo, bar, ...'
     *                                Or a key value pairs map of event name to handler.
     *                                For example: { foo: handlerFoo, bar: handlerBar, ... }
     * @param {Function} [handler] - Handler for string type event.
     * @return {Function} - Function to unsubscribe from registered events.
     */
    function on(event, handler) {
      let unSubscribeFuns;

      const events = _.isString(event)
        ? event.split(',').map(_.trim)
        : _.map(event, (subHandler, subEvent) => ({ subEvent, subHandler }));

      unSubscribeFuns = _.map(events, (info: any) => doOn(info.subEvent || info, info.subHandler || handler));

      if (unSubscribeFuns.length > 0) {
        return (_.flow as any)(...unSubscribeFuns);
      }
    }

    /**
     * @private
     */
    function doOn(event, handler) {
      const handlerList = notifyInfo[event] || (notifyInfo[event] = []);
      let unSubscribeFun = _.noop;

      if (_.isFunction(handler)) {
        handlerList.push(handler);
        unSubscribeFun = () => un(event, handler);
      }

      return unSubscribeFun;
    }

    function fireEvent(event, ...args) {
      const handlerList = notifyInfo[event] || [];

      if (handlerList.length > 0) {
        _.each(handlerList, handler => handler(...args));
      }
    }

    function un(event, handler?) {
      if (handler) {
        _.pull(notifyInfo[event], handler);
      } else {
        delete notifyInfo[event];
      }
    }

    function unregisterAll() {
      notifyInfo = {};
    }
  }

  /**
   * Extend the object to be eventable via the internal event bus.
   */
  static makeEventable(object) {
    if (!object._eventBus) {
      const eventBus = EventService.getEventBus();

      object._eventBus = eventBus;
      _.defaults(object, eventBus);
    }
  }
}
